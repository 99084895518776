@font-face {
  font-family: 'Noto Sans';
  src: url('./assets/fonts/NotoSans-Regular.ttf');
}

@font-face {
  font-family: 'Noto Sans Bold';
  src: url('./assets/fonts/NotoSans-Bold.ttf');
}

@font-face {
  font-family: 'Zen Tokyo Zoo';
  src: url('./assets/fonts/ZenTokyoZoo-Regular.ttf');
}

@font-face {
  font-family: 'Bungee Shade';
  src: url('./assets/fonts/BungeeShade-Regular.ttf');
}

@font-face {
  font-family: 'Audiowide';
  src: url('./assets/fonts/Audiowide-Regular.ttf');
}
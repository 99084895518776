.discounts-area-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #00000060;
  display: flex;
  z-index: 10;
  
}
.discounts-area-container{
  width: 80vw;
  height: 80vh;
  margin: auto;
  background: #FFFFFF;
  padding: 50px;
  position: relative;
}
.discounts-area-container.no-discounts{
  height: 10%;
  max-width: 300px;
  max-height: 65px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}
.discounts-area-container .close-discount-btn{
  position: absolute;
  font-size: 25px;
  cursor: pointer;
  transition: all .2s ease;
  color: #2f73b1;
  border: 1px solid #2f73b1;
  background: #dafafe;
  border-radius: 50%;
  /* line-height: 10px; */
  top: -15px;
  right: -15px;
}
.discount-header.discount-header-empty{
  width: 300px;
  text-align: center;
}
.discounts-area-container .close-discount-btn:hover{
  background: #2f73b1;
  color: #dafafe;
}
.discount-header{
  background: #dbfbff;
  padding: 10px;
  font-size: 20px;
  width: 250px;
  font-weight: 600;
  color: #2f73b1;
}

.discounts{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}

.discounts .discount-line{
  width: calc(25% - 10px);
  margin: 5px;
}
.discount-desc{
  padding: 16px;
  font-size: 12px;
  min-height: 80px;
}
.discount-actions{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.discount-action-edit{
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  background: #003b56;
  color: #fff;
  border: 1px solid #003b56;
  border-radius: 7px;
  transition: all .2s ease;
  margin-bottom: 15px;
  margin-top: 5px;
  width: 100%;
  text-align: center;
}
.discount-action-edit:hover{
  background: #fff;
  color: rgb(19, 19, 19);
}

.discount-type{
  padding: 5px 10px;
  background: #2f73b1;
  border-radius: 7px;
  margin: 5px;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
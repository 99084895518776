.products-container{
  background: #f5f5f5;
  width: 100%;
}
.products-home-page {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background: #fff;
}

.products-home-wrapper{
  width: 80%;
}
.filters-home-wrapper{
  width: 20%;
}

.products-home-page .options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.products-home-page .options .btn-sort, .products-home-page .options .btn-change-view-type {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.products-home-page .options .btn-sort.open .menu, .products-home-page .options .btn-change-view-type.open .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.products-home-page .options .btn-sort .menu, .products-home-page .options .btn-change-view-type .menu {
  min-width: calc(100% - 10px);
  display: none;
  position: absolute;
  top: 33px;
  left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-color: #FFF;
  z-index: 3;
}

.products-home-page .options .btn-sort .menu > *, .products-home-page .options .btn-change-view-type .menu > * {
  width: calc(100% - 8px - 8px);
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  text-align: right;
}

.products-home-page .options .btn-sort .menu > *.selected, .products-home-page .options .btn-change-view-type .menu > *.selected {
  background-color: #DDD;
}

.products-home-page .options .btn-sort .menu > *:hover, .products-home-page .options .btn-change-view-type .menu > *:hover {
  background-color: #111;
  color: #EEE;
}

.products-home-page .options .btn-change-view-type .menu > * {
  text-align: center;
}
.filter--toggle{
  display: none;
}
.products-home-page .filters {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-transition: height 300ms ease-out;
  transition: height 300ms ease-out;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow: auto;
}
.products-home-page .filters::-webkit-scrollbar {
  width: 8px;
}
.products-home-page .filters::-webkit-scrollbar {
  -webkit-appearance: none;
  display: inherit;
}
.products-home-page .filters::-webkit-scrollbar-thumb {
  background: #ff000090;
}
.products-home-page .filters::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.products-home-page .filters.open {
  height: auto;
  visibility: visible;
}


.products-home-page .filters .categories {
  height: 100%;
  overflow-y: auto;
}

.products-home-page .filters .categories::-webkit-scrollbar {
  width: 8px;
}

.products-home-page .filters .categories::-webkit-scrollbar-track {
  background: #FFF;
}

.products-home-page .filters .categories::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 27px;
}

.products-home-page .filters .categories::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.products-home-page .filters .categories .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.products-home-page .filters .categories .list .item {
  width: 100%;
  cursor: pointer;
}

.products-home-page .filters .categories .list .item .name {
  width: calc(100% - 8px - 8px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.products-home-page .filters .categories .list .item .name:hover {
  background-color: #111;
  color: #EEE;
}

.products-home-page .filters .categories .list .item .submenu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.products-home-page .filters .categories .list .item .submenu.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.products-home-page .filters .categories .list .item .submenu.closed {
  display: none;
}

.products-home-page .filters .categories .list .item .submenu > * {
  text-decoration: none;
  width: calc(100% - 16px - 16px);
  padding: 4px;
  padding-left: 16px;
  border-radius: 4px;
  color: #333;
}

.products-home-page .filters .categories .list .item .submenu > *:hover {
  background-color: #111;
  color: #EEE;
}

.products-home-page .filters .price > * {
  margin: 8px 0;
}

.products-home-page .filters .price .price-input {
  position: relative;
}

.products-home-page .filters .price .price-input input {
  outline: none;
  border: none;
  width: 50px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #EEE;
}

.products-home-page .filters .price .price-input input:nth-of-type(2) {
  margin-left: 16px;
}

.products-home-page .filters .price .price-input span {
  position: absolute;
  top: 5px;
  right: 10px;
}

.products-home-page .filters .price .price-input:nth-of-type(2) {
  border-left: 1px solid #CCC;
}

.products-home-page .filters .attributes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.products-home-page .filters .attributes .attribute {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 4px 0;
  font-size: 14px;
  width: 100%;
}

.products-home-page .filters .attributes .attribute span {
  min-width: 100px;
  text-align: right;
}

.products-home-page .filters .attributes .attribute .choices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.products-home-page .filters .attributes .attribute .choices .choice.size-choice {
  width: 100%;
  text-align: left;
  font-size: 18px;
  margin: 2px;
  cursor: pointer;
}

.products-home-page .filters .attributes .attribute .choices .choice.size-choice::before {
  content: " ";
  display: inline-block;
  width: 16px;
  margin-right: 5px;
  height: 16px;
  border: 1px solid #eee;
}
.products-home-page .filters .attributes .attribute .choices .choice.size-choice.selected::before {
  content: "✓";
  display: inline-block;
  width: 16px;
  margin-right: 5px;
  height: 16px;
  border: 1px solid #eee;
  font-size: 20px;
  line-height: 18px;
}

.products-home-page .filters .attributes .attribute .choices .choice.color-choice {
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 4px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.products-home-page .filters .attributes .attribute > .name{
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #a7a7a7;
}
.price > .header{
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  width: 100%;
}
.products-home-page .filters .attributes .attribute .choices .choice.color-choice.selected{
  -webkit-box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.7); 
  box-shadow: 0px 0px 2px 3px rgb(0 0 0 / 70%);
}

.products-home-page .filters .attributes .attribute .choices .choice.color-choice .color-value{
  width: 100%;
  height: 100%;
}


.products-home-page .filters.filters--list {
  height: unset;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.products-home-page .filters.filters--list .attributes {
  padding-left: unset;
}

.products-home-page .filters.filters--list .attributes .attribute {
  margin-top: 16px;
}

.products-home-page .filters.filters--list .attributes .attribute .name {
  min-width: 75px;
  max-width: 75px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 100%;
  padding-right: 8px;
}



.products-home-wrapper > div > .list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  
}

.products-home-wrapper > div > .list > * {
  width: calc(25% - 20px);
  padding: 10px;
  box-sizing: border-box;
  margin: 10px;
}

.products-home-wrapper > div > .list .no-results-message {
  width: 100%;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #AAAAAA;
}

.products-home-wrapper > div > .list .no-results-message svg {
  font-size: 65px;
}

.products-home-wrapper > div > .list .no-results-message span {
  font-size: 30px;
}

.products-home-wrapper .pagination, .products-home-page .skeleton-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 16px 0;
  background-repeat: no-repeat;
}

.products-home-wrapper .pagination a, .products-home-wrapper .pagination div, .products-home-wrapper .skeleton-pagination a, .products-home-wrapper .skeleton-pagination div {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 4px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  background-color: #DDD;
  color: #333;
  cursor: pointer;
}

.products-home-wrapper .pagination a.active, .products-home-wrapper .pagination div.active, .products-home-wrapper .skeleton-pagination a.active, .products-home-wrapper .skeleton-pagination div.active {
  background-color: #111;
  color: #EEE;
}

@media screen and (max-width: 999px) {
  .products-home-wrapper > div > .list > * {
    width: calc(31% - 8px - 8px - 8px);
  }
  .filter--toggle{
    display: block;
  }
  .products-home-page {
    flex-direction: column;
  }
  
  .products-home-wrapper{
    width: 100%;
  }
  .filters-home-wrapper{
    width: 100%;
  }
  .products-home-page .filters {
    visibility: hidden;
    height: 0;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .products-home-wrapper .filters {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

  }
  .products-home-wrapper .filters .attributes {
    padding-left: unset;
  }
  .products-home-wrapper .filters .attributes .choices {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .products-home-wrapper > div > .list > * {
    width: calc(45% - 8px - 8px - 2px);
  }
}

@media screen and (max-width: 475px) {
  .products-home-wrapper > div > .list > * {
    width: calc(100%);
  }
  .products-home-wrapper .options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    gap: 10px;
  }
  .products-home-wrapper .options .btn-change-view-type {
    display: none;
  }
}
/*# sourceMappingURL=ProductsPage.css.map */
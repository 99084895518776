.prices-list, .price-row{
  list-style: none;
  position: relative;
}
.prices-list{
  padding: 0;
  border-radius: 7px;
}
.price-row{
  padding: 5px 10px;
  transition: all .2s ease;
}
.price-row.active{
  background: #e2f6ff;
}
.price-row:not(:last-child){
  border-bottom: 1px solid #8b8b8b;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.price-row li{
  display: inline-block;
  margin: 10px;
  width: calc(50% - 20px);
  min-width: 150px;
}
.price-row ul{
  padding: 0;
}
.price-row ul li:last-child{
  text-align: right;
}
.header-prices{
  background: #8b8b8b;
  color: #fff;
  font-size: 17px;
}
.prices-wrapper{
  width: 100%;
}
.old-price-li{
  font-size: 12px;
  color: #828589;
  text-decoration: line-through;
  margin-right: 7px;
}
.discount-scale {
  background: #e2ffec61;
}
.discount-scale-icon {
  position: absolute;
  left: -16px;
    top: 4px;
    /* transform: translateY(-50%); */
    rotate: 320deg;
    color: rgb(66, 128, 66);
    cursor: pointer;
}

.discount-scale-reason {
  position: absolute;
    background: #009688;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    border-bottom: 3px solid #157970;
    border-left: 3px solid #157970;
    /* border-top: 1px solid #157970; */
    /* border-right: 1px solid #157970; */
    right: 10px;
    top: 0;
    display: none;
}

.discount-scale-icon:hover + .discount-scale-reason{
  display: inline-block;
}
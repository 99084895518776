
.color-pick{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.color-pick .color-value{
  width: 20px;
  height: 20px;
  margin: 5px 2px;
}
.color-pick .color-value.active{
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.25); 
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.25);
}
.i360-value{
  background: 40% 40% no-repeat grey;
  display: flex;
  justify-content: center;
  color: #fff;
}
.i360-controls button{
  background: none;
  border: 1px solid #787878;
  padding: 10px;
  font-size: 20px;
  border-radius: 7px;
  margin: 0 5px;
  cursor: pointer;
  color: #545454;
}
.product-gallery-container .slick-prev {
  left: 0 !important;
  z-index: 10;
}
.product-gallery-container .slick-next {
  right: 0 !important;
  z-index: 10;
}
.product-gallery-container .product-gallery-item { 
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-gallery-container .slick-slide div {
  height: 100%;
}
.product-gallery-container .product-gallery-item-preview {
  width: 75px !important;
  height: 75px !important;
}

.product-gallery-container .product-gallery-item-preview img {
  object-fit: cover !important;
  width: 70px !important;
  height: 70px !important;
}

.product-gallery-container svg.slick-arrow {
  width: 40px !important;
  height: 40px;
  font-size: 20px;
  color: #545454;
}

.product-gallery-container svg.slick-arrow:hover {
  color: #000;
}
.product-gallery-container .slick-active .product-gallery-item-preview img {
  box-shadow: 0 0 0 #000;
}
/*# sourceMappingURL=ProductAttributes.css.map */
.downloads-page{
    max-width: 1024px;
    flex-wrap: wrap;
    margin: auto;
    display: flex;
    column-gap: 5px;
}
.downloads-page .product-item{
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 50px;
    width: calc(33% - 5px);
    box-sizing: border-box;
    margin: 0 !important;
    margin-bottom: 10px !important;
}

.downloads-page .file-icon {
    color: #c7c7c7;
    margin-left: 10px;
    font-size: 25px;
    
    /* box-shadow: 0px 0px 10px 1px #000; */
}

.downloads-page .product-code {
    font-size: 16px;
    font-weight: 600;
    margin: 0px 10px;
    color: #4d5254;
}

.downloads-page .product-date {
    margin-left: 10px;
}

.downloads-page .file-download {
    border-radius: 50px;
    /* background: #2196f3; */
    padding: 10px;
    padding-left: 0;
    color: #fff;
    
}
.download-section {
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 2px 10px;
    flex-wrap: wrap;
    background: #2196f3;
    cursor: pointer;
}
.download-section span {
    color: #fff;
}
.downloads-page .search-products {
    outline: none;
    max-width: 400px;
    width: 100%;
    max-width: calc(100% - 10px - 40px);
    height: 35px;
    border: 1px solid #EEE;
    border-radius: 24px;
    padding: 5px 10px;
    padding-left: 40px;
    background-color: #EEE;
    font-size: 18px;
    color: #333;
}
.downloads-page .search-area{
    width: 100%;
    margin: 10px 5px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.downloads-page .search-area svg {
    position: absolute;
    top: 13px;
    left: 13px;
    font-size: 20px;
    color: #333;
}
.downloads-page .search-item{
    position: relative;
    width: 100%;
    max-width: 400px;
}

@media only screen and (max-width:950px) {
    .downloads-page .product-item {
        width: calc(50% - 5px);
    }
}
@media only screen and (max-width:600px) {
    .downloads-page .product-item {
        width: 100%;
    }
}

/* No CSS *//*# sourceMappingURL=Terms.css.map */

.bg-white {
    background: #fff;
}
.term-content {
    padding: 20px;
}
.term-content img {
    max-width: 100%;
    height: auto;
}
.title {
    padding-top:10px;
}
.App > .basket .backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.App > .basket .container {
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  max-width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-color: #FFF;
  z-index: 999;
  -webkit-transition: right 200ms ease-in-out;
  transition: right 200ms ease-in-out;
}

.App > .basket .container .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% - 16px - 16px);
  padding: 16px;
  border-bottom: 1px solid #EEE;
}

.App > .basket .container .header > h3 {
  font-family: 'Noto Sans Bold';
  font-weight: normal;
  font-size: 18px;
}

.App > .basket .container .header .btn-close {
  cursor: pointer;
}

.App > .basket .container .products {
  -webkit-box-flex: 10;
      -ms-flex: 10;
          flex: 10;
  width: calc(100% - 16px - 16px);
  max-height: 70%;
  overflow: auto;
  padding: 16px;
}

.App > .basket .container .products .product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border-bottom: 1px solid #EEE;
  margin: 8px 0;
  padding-bottom: 8px;
}

.App > .basket .container .products .product .image {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 0;
  padding-bottom: 25%;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.App > .basket .container .products .product .info {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 8px;
  padding-left: 16px;
}

.App > .basket .container .products .product .info .name {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-family: 'Noto Sans Bold';
}

.App > .basket .container .products .product .info .price {
  font-size: 14px;
}

.App > .basket .container .products .product .info > .controls {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.App > .basket .container .products .product .info > .controls > * {
  margin: 0 4px;
}

.App > .basket .container .products::-webkit-scrollbar {
  width: 8px;
}

.App > .basket .container .products::-webkit-scrollbar-track {
  background: #EEE;
}

.App > .basket .container .products::-webkit-scrollbar-thumb {
  background: #111;
  border-radius: 16px;
}

.App > .basket .container .products::-webkit-scrollbar-thumb:hover {
  background: #111;
}

.App > .basket .container .amounts {
  width: calc(100% - 16px - 16px);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 16px;
}

.App > .basket .container .amounts .subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 16px;
  border-top: 1px solid #EEE;
  font-family: 'Noto Sans Bold';
}

.App > .basket .container > .controls {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  width: calc(100% - 16px - 16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 16px;
}

.App > .basket .container > .controls [class^="btn-"] {
  text-decoration: none;
  width: 100%;
  padding: 8px 0;
  font-size: 14px;
  font-family: 'Noto Sans Bold';
  text-align: center;
  cursor: pointer;
}

.App > .basket .container > .controls .btn-checkout {
  border: 1px solid #111;
  background-color: #111;
  color: #EEE;
}

.App > .basket .container > .controls .btn-cart {
  border: 1px solid #000;
  margin-top: 4px;
  background-color: #FFF;
  color: #000;
}

.App > .basket.open .backdrop {
  display: block;
}

.App > .basket.open .container {
  right: 0;
}

@media screen and (max-width: 768px) {
  .App > .basket .container {
    width: 100%;
    right: -100%;
  }
}
.original-price{
  text-decoration: line-through;
  color: #FF0000;
}
.basket-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 2;
}
.basket-loading > img{
  width: 100px;
  height: 100px;
}
.products.invalid-products {
  max-height: 25% !important;
}
.product.invalid-product {
  opacity: .4;
}
.products.invalid-products > h4{
  color: #c71111;
}
.btn-clear-invalid{
  padding: 5px 10px;
  background: #fff;
  border: 1px solid;
  transition: all .2s ease;
  cursor: pointer;
}
.btn-clear-invalid:hover{
  background: #000;
  color: #fff;
}
.form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #EEE;
}
.form-group input {
    max-width: 50px;
}
.btn-save {
    border: 1px solid #111;
    background-color: #111;
    color: #EEE;
    text-decoration: none;
    width: 100%;
    padding: 8px 0;
    font-size: 14px;
    font-family: 'Noto Sans Bold';
    text-align: center;
    cursor: pointer;
}
/*# sourceMappingURL=Basket.css.map */